var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bill-client-payment-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "客户付款",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.payment ? _c('div', {
    staticClass: "bill-client-payment-content"
  }, [_c('van-cell-group', {
    attrs: {
      "title": "账单明细"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "订单总额",
      "value": '￥' + _vm.payment.amount
    }
  }), _vm.depositAmount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减定金",
      "value": '￥' + _vm.depositAmount
    }
  }) : _vm._e(), _vm.payment.red_packet_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减红包",
      "value": '￥' + _vm.payment.red_packet_amount
    }
  }) : _vm._e(), _vm.payment.coupon_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减优惠券",
      "value": '￥' + _vm.payment.coupon_amount
    }
  }) : _vm._e(), _vm.payment.return_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减退货",
      "value": '￥' + _vm.payment.return_amount
    }
  }) : _vm._e(), _vm.payment.unpaid_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减签单",
      "value": '￥' + _vm.payment.unpaid_amount
    }
  }) : _vm._e(), _vm.payment.decimal_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减尾数",
      "value": '￥' + _vm.payment.decimal_amount
    }
  }) : _vm._e(), _vm.payment.expense_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "报销(额外计算)",
      "value": '￥' + _vm.payment.expense_amount
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "应交",
      "value": '￥' + _vm.payment.real_amount
    }
  })], 1), _vm.payment.unpaid_amount < 0 ? _c('van-cell-group', {
    attrs: {
      "title": "签单明细"
    }
  }, [_vm.payment.unpaid_return_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "签单退货金额",
      "value": '￥' + _vm.payment.unpaid_return_amount,
      "value-class": "cell-amount-value"
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "本次签单金额",
      "value": '￥' + _vm.payment.real_unpaid_amount,
      "value-class": "cell-amount-value"
    }
  })], 1) : _vm._e(), _c('van-cell-group', {
    attrs: {
      "title": "支付明细"
    }
  }, [_vm.payment.wxpay_amount > 0 ? _c('van-cell', {
    attrs: {
      "title": "微信",
      "value": '￥' + _vm.payment.wxpay_amount
    }
  }) : _vm._e(), _vm.payment.cash_amount != 0 ? _c('van-cell', {
    attrs: {
      "title": "现金",
      "value": '￥' + _vm.payment.cash_amount
    }
  }) : _vm._e(), _vm.payment.trans_amount > 0 ? _c('van-cell', {
    attrs: {
      "title": "转账",
      "value": '￥' + _vm.payment.trans_amount
    }
  }) : _vm._e()], 1)], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }