<template>
  <div class="bill-client-payment-container">
    <my-nav-bar
      title="客户付款"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <div v-if="payment" class="bill-client-payment-content">

        <van-cell-group title="账单明细">
          <van-cell title="订单总额" :value="'￥' + payment.amount" />
          <van-cell v-if="depositAmount < 0" title="扣减定金" :value="'￥' + depositAmount" />
          <van-cell v-if="payment.red_packet_amount < 0" title="扣减红包" :value="'￥' + payment.red_packet_amount" />
          <van-cell v-if="payment.coupon_amount < 0" title="扣减优惠券" :value="'￥' + payment.coupon_amount" />
          <van-cell v-if="payment.return_amount < 0" title="扣减退货" :value="'￥' + payment.return_amount" />
          <van-cell v-if="payment.unpaid_amount < 0" title="扣减签单" :value="'￥' + payment.unpaid_amount" />
          <van-cell v-if="payment.decimal_amount < 0" title="扣减尾数" :value="'￥' + payment.decimal_amount" />
          <van-cell v-if="payment.expense_amount < 0" title="报销(额外计算)" :value="'￥' + payment.expense_amount" />
          <van-cell title="应交" :value="'￥' + payment.real_amount" />
        </van-cell-group>

        <van-cell-group v-if="payment.unpaid_amount < 0" title="签单明细">
          <van-cell v-if="payment.unpaid_return_amount < 0" title="签单退货金额" :value="'￥' + payment.unpaid_return_amount" value-class="cell-amount-value" />
          <van-cell title="本次签单金额" :value="'￥' + payment.real_unpaid_amount" value-class="cell-amount-value" />
        </van-cell-group>

        <van-cell-group title="支付明细">
          <van-cell v-if="payment.wxpay_amount > 0" title="微信" :value="'￥' + payment.wxpay_amount" />
          <!-- <van-cell title="支付宝" :value="'￥' + payment.alipay_amount" /> -->
          <van-cell v-if="payment.cash_amount != 0" title="现金" :value="'￥' + payment.cash_amount" />
          <van-cell v-if="payment.trans_amount > 0" title="转账" :value="'￥' + payment.trans_amount" />
        </van-cell-group>
      </div>
    </div>
  </div>
</template>

<script>
import billApi from '@/api/bill'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'BillClientPayment',
  components: { myNavBar },
  data() {
    return {
      payment: null
    }
  },
  computed: {
    depositAmount() {
      return (Number(this.payment.deposit_wxpay_amount) +
          Number(this.payment.deposit_alipay_amount) +
          Number(this.payment.deposit_cash_amount)).toFixed(2)
    }
  },
  created() {
    this.getPayment()
  },
  methods: {
    getPayment() {
      const params = {
        payment_id: this.$route.query.payment_id
      }
      billApi.orderPayment(params).then(res => {
        this.payment = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .bill-client-payment-content {
    padding: 0 15px 15px 15px;
    .van-cell {
      &__value {
        color: #ee0a24;
      }
    }
  }
</style>
